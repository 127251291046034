<template>
    <PageHeader :title="title"/>
    <tablecustom 
      @search="searchB"  
      :columns="columns" 
      :rows="rows" 
      :pages="objPages"
      :showpag="true"
      :createdform="this.perms['1501'] || this.perms['2000']" 
      :removeitem="this.perms['1503'] || this.perms['2000']"
      @create="create" 
      @open="open"
      @getdata="getdata"
      @changelimit="changelimit" 
      :dataParams="this.dataParams"
      :objParams="this.objParams"
      @changeFilter="changeFilter" 
    />

    <viewbox 
      v-if="showModal == true"  
      @edit = "edit"
      @close="closeCreate" 
      :objcard="objcard"
      @getdata="getdata"
    ></viewbox>
    
    <createdForm
      :perm = "this.perm"
      :obj = "this.form"
      v-if="modalCreatedBox == true" 
      @close="closeCreate" 
    />

</template>

<script>
import PageHeader from "@/components/page-header";
import { ProvidersProducts } from '@/API.js';
import tablecustom from '@/components/globaltable/index';
import createdForm from './create'
import viewbox from './view'
import { storeS } from "@/store";

let apiServe = new ProvidersProducts();

export default {
  components: {
    PageHeader,
    createdForm,
    viewbox,
    tablecustom
  },
  data() {
    return {
      form: '',
      perm: false,
      title: this.$t('providers'),
      search: '',
      modalCreatedBox: false,
      statuscreated: true,
      showModal: false,
      ifNextopen: "",
      ifPrevopen: "",
      dataParams: {
          status: true,
          page: "provider"
      },
      objParams:{
        page: '1',
        pagelimit: '10',
        search: '',
        providerType: '',
      },
      dataParams: {
        status: true,
        page: "providers"
      },
      columns: [
        {
          name: this.$t("Provider"),
          text: "name",
          align: "left",
          status: true,
        },
        {
          name: this.$t("telephone"),
          text: "phone",
          align: "right",
          status: true,
        },
        {
          name: this.$t("site"),
          text: "website",
          align: "right",
          status: true,
        },
      ],
      rows: [],
      objPages: {},
      objcard: {}
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    changeFilter(name, value) {
      console.log('a', name, value)
      this.objParams[name] = value
      this.getdata()
    },
    closeCreate() {
      this.modalCreatedBox = false;
      this.perm = false; //чистим следы
      this.showModal = false;
      this.getdata();
    },
    getdata(page){
      this.objParams.page = page != undefined ? page : '1';
      apiServe.getAllProviders(this.objParams).then(result => {
        if(result.status == 'done') {
          this.rows = result.data.items
          this.objPages = result.data;
        } else {
          this.$toast.error(this.$t('error') + ` #1400`);
        }
      })
    },
    create: function(){
        this.modalCreatedBox = true
    },
    open: function(e){
      // var id = e.id ? e.id : e;
      // this.ifPrevopen = id == this.filteredItems[0].id ? false : true;
      // this.ifNextopen = id == this.filteredItems[this.filteredItems.length-1].id ? false : true;
      apiServe.getProvider(e.providerId).then(result => {
        if(result.status == 'done') {
          this.objcard = result.data;
          this.showModal = true;
        }
      })
    },
    prevOpen(e){
      var current_id = e;
      var prev_id = ""; 

      for(var value in this.filteredItems){
        if(this.filteredItems[value].id == current_id){
          prev_id = this.filteredItems[Number(value)-Number('1')].id;
        }
      }
      this.open(prev_id);
    },
    nextOpen(e){
      var current_id = e;
      var next_id = "";

      for(var value in this.filteredItems){
        if(this.filteredItems[value].id == current_id){
          next_id = this.filteredItems[Number(value)+Number('1')].id;
        }
      }
      this.open(next_id);
    },
    searchB(e){
      this.objParams.search = e
      this.getdata();
    },
    changelimit: function(limit){
        this.objParams.pagelimit = limit;
        this.getdata();
    },
    edit(e) {
      this.showModal = false
      this.form = e;
      this.perm = true
      this.modalCreatedBox = true //запрос на редактированние
    }
  },
  computed: {
    // filteredItems: function() {
    //     return this.rows
    //         // По полю поиска
    //         .filter(item => {
    //             return this.search == '' || item.label.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.manager.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
    //         })
    // },
    user() {
      return storeS.userbase
    },
    perms() {
      return storeS.perms
    }
  },
  mounted() {
    this.eventBus.on('saveProvider', (status) => {
      if(status == true) {
        this.getdata();
      }
    })
  }
};
</script>

<style scoped>
.tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
}
</style>
